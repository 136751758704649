import {
  DEMAND_STATES,
  DEMAND_PRIORITIES,
  DEMAND_NATURES,
} from '@/model/Demand'
import { LocaleMessages } from 'vue-i18n'

const messages: LocaleMessages = {
  en: {
    hello: `Hello`,
    // Small texts
    login: `Login`,
    logout: `Logout`,
    add: `Add`,
    new: `New`,
    edit: `Edit`,
    expand: `Expand`,
    collapse: `Collapse`,
    delete: `Delete`,
    sort: `Sort`,
    search: `Search`,
    reinit: `Reinit`,
    create: `Create`,
    close: `Close`,
    download: `Download`,
    upload: `Upload`,
    validate: `Validate`,
    cancel: `Cancel`,
    show: `Show`,
    hide: `Hide`,
    downloading: `Downloading`,
    uploading: `Uploading`,
    name: `Name`,
    price: `Price`,
    date: `Date`,
    noData: `No data`,
    webapp: `Web App`,
    previous: `Previous`,
    activated: `Activated`,
    desactivated: `Desactivated`,
    linkToProject: `Link to project`,
    unlinkToProject: `Unlink from project`,
    previousShort: `Prev.`,
    nextShort: `Next`,
    connection: `Connection`,
    home: `Home`,
    myAccount: `My account`,
    myAccountShort: `Account`,
    logoutShort: `Logout`,
    contacts: `Contacts`,
    files: `Files`,
    subscription: `Subscription`,
    available: `Available`,
    month: `Month`,
    months: `Months`,
    point: `Point`,
    points: `Points`,
    user: `User`,
    users: `Users`,
    invoice: `Invoice`,
    project: `Project`,
    projects: `Projects`,
    contract: `Contract's history`,
    contracts: `Contract's history`,
    story: `Story`,
    stories: `Stories`,
    forgotMyPassword: `Forgot password ?`,
    availablePoints: `Available points`,
    report: `Report`,
    logo: `Logo`,
    chooseLang: `Choose your language`,
    // Plurals
    nbMonth: 'no month | {n} month',
    nbPoints: 'no point | {n} point | {n} points',
    nbUser: 'no user | {n} user | {n} users',
    nbInvoice: 'no invoice | {n} invoice | {n} invoices',
    nbProject: 'no project | {n} project | {n} projects',
    nbStory: 'no story | {n} story | {n} stories',
    nbContract:
      'no contract history | {n} contract history | {n} contract history',
    // Long texts
    sureDeleteReport: `Are you sure to delete this report`,
    onlineAccess: `Access to WebApp`,
    onlineAccessTest: `Access to WebApp Test`,
    goToRequests: `Go on demands`,
    chooseNewPassword: `Choose a new password`,
    askResetPassword: `Ask for a new password`,
    askResetPasswordMessage: `In order to receive a new password, please write your username or email and follow email's instructions`,
    choosePasswordActivateAccount: `Choose a new password to activate your account`,
    activateAccount: `Activate account`,
    allProjects: `All projects`,
    editContract: `Update contract history`,
    editStory: `Update story`,
    editMyData: `Update my data`,
    editMyPassword: `Update my password`,
    editUser: `Update this user`,
    deleteMyAccount: `Delete my account`,
    oldPassword: `Old password`,
    newPassword: `New password`,
    iAmSur: `I am sure`,
    deleteAccountPassphrase: `Delete this account`,
    askIfSurDeleteOwnAccount: `Are you sure you want to delete this account ? Please write the following words if you really want to proceed.`,
    editImage: `Edit image`,
    addImage: `Add image`,
    leaveBlankToNotChange: `Keep empty for no modification`,
    invalidPassword: `Password must be at least 8 char long.`,
    invalidForm: `Form is invalid`,
    linkUserToProject: `Link user to project`,
    unlinkUserToProject: `Unlink user from project`,
    selectProject: `Select a project`,
    addReport: `Add a report`,
    allAccounts: `All accounts`,
    maintenance: `Maintenance`,
    pageNotFound: `Page not found`,
    sendEmailToCustomers: `Send email to customers`,
    listOfDemands: `Demands of {project}`,
    askDeleteInvoice: `Are you sure you ant to delete this invoice ?`,
    Router: {
      home: `Dashboard - Home`,
      login: `Dashboard - Connexion`,
      askResetPassword: `Dashboard - Ask ne password`,
      resetPassword: `Dashboard - Reinit password`,
      activateAccount: `Dashboard - Activate account`,
    },
    Roles: {
      undefined: 'Undefined',
      superadmin: 'Superadmin',
      1: 'Superadmin',
      admin: 'Admin',
      2: 'Admin',
      developper: 'Developper',
      3: 'Developper',
      salesperson: 'Saleperson',
      4: 'Saleperson',
      user: 'User',
      5: 'User',
      visitor: 'Visitor',
      6: 'Visitor',
    },
    User: {
      new: `New user`,
      askDelete: `Are you sure you want to delete this user ?`,
      username: `Username`,
      usernameOrEmail: `Username or email`,
      password: `Password`,
      email: `Email`,
      role: `Role`,
    },
    Contract: {
      askDelete: `Are you sure you want to delete this contract history ?`,
      date: `Date`,
      price: `Price`,
      points: `Points`,
      new: `New contract history`,
    },
    Story: {
      askDelete: `Are you sure you want to delete this story ?`,
      name: `Name`,
      date: `Date`,
      points: `Points`,
      description: `Description`,
      new: `New story`,
    },
    Project: {
      name: `Name`,
      points: `Points`,
      price: `Price`,
      version: `Version`,
      url: `URL`,
      testurl: `URL test`,
      new: `New project`,
      edit: `Edit project`,
      askDelete: `Are you sure you want to delete this project ? `,
    },
    Demand: {
      noPriority: `No priority`,
      noDescription: `No description`,
      demandOf: `Demand of {name}`,
      editedBy: `Edited by {name}, {date} ago`,
      createdBy: `Created by {name}, {date} ago`,
      createdAt: `Creation date`,
      editedAt: `Last modification`,
      checkedBy: `Approved {name}`,
      state: `State`,
      State: {
        [DEMAND_STATES.ABORT]: `Canceled`,
        [DEMAND_STATES.DONE]: `Finish`,
        [DEMAND_STATES.PROCESSING]: `Processing`,
        [DEMAND_STATES.WAITING]: `Waiting`,
      },
      priority: `Priorité`,
      Priority: {
        [DEMAND_PRIORITIES.LOW]: `Low`,
        [DEMAND_PRIORITIES.MEDIUM]: `Medium`,
        [DEMAND_PRIORITIES.HIGH]: `High`,
        [DEMAND_PRIORITIES.CRITIC]: `Critic`,
      },
      nature: `Nature`,
      Nature: {
        [DEMAND_NATURES.FIX]: `Correction`,
        [DEMAND_NATURES.IMPROVEMENT]: `Improvement`,
      },
      description: `Description`,
      version: `Version`,
      browser: `Browser`,
      os: `System`,
      points: `Points`,
      point: `Point`,
      nbPoints: `{n} point | {n} point | {n} points`,
      nbFiles: `{n} file | {n} file | {n} files`,
      name: `Name`,
      edit: `Edit demand`,
      askDelete: `Are you sure you want to delete this demand ?`,
      noVersion: `No version`,
      noBrowser: `No browser`,
      noOS: `No OS`,
      new: `New demand`,
    },
  },
}

export default messages
